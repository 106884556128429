import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 198 64.94"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M.77 62.25c-.31-.34-.49-.79-.49-1.35V5.13c0-.56.18-1.01.49-1.35.53-.7 1.9-.46 2.68-.5.54 0 .98.16 1.31.5.32.34.47.79.47 1.35V60.9c.02 1.1-.68 1.86-1.78 1.85-.79-.07-2.14.2-2.68-.5m17.67-3.9c-3.83-3.96-5.62-11.02-5.44-17.38-.39-11.46 5.46-22.87 17.83-22.63 12.8-.1 18.37 10.58 17.91 22.86.02 1.09-.79 1.86-1.86 1.85H18.03c-.23 15.59 16.2 21.49 25.18 8.97.25-.16.69-.24 1.35-.24 1.14-.07 3.03-.07 2.99 1.51-2.9 11.03-22.04 13.93-29.11 5.06m25.38-19.98c.54-20.15-26.43-20.25-25.79.01h25.79zm24.23 23.84c-.43-.37-.79-.87-1.05-1.55L51.76 21.81c-.37-1.05.36-2.63 1.61-2.6h6.73c1.07 0 1.81.53 2.19 1.58l10.69 29.24L83.69 20.8c.25-.88 1.05-1.65 2.11-1.59h6.72c1.31-.06 1.98 1.53 1.62 2.6L78.99 60.66c-.6 1.4-1.56 2.1-2.92 2.1-1.3-.18-7.04.47-8.02-.54zm32.61-3.98c-4.01-3.78-5.79-10.59-5.72-17.36-.1-13.03 6.82-22.59 20.03-22.53 13.97-.27 20.77 10.68 20.1 23.88.01 1.11-.89 2.04-2.03 2h-26.9c-.47 10.24 9.46 15.84 16.36 7.61 1.01-1.18 1.05-1.29 2.53-1.33.92.15 7.77-.4 8.39.42 1.38 1.77-.1 3.69-1.79 5.91-6.86 8.23-23.63 9.25-30.97 1.4m23.23-21.11c.53-14.76-18.32-14.79-17.75 0zm-13.62-25.54c-.6-.42 6.86-8.89 7.07-9.38.95-1.2 1.49-1.57 2.98-1.59h8.2c1.39-.1 2.01 1.74 1.14 2.68l-10.14 8.54c-.99.78-1.59 1.09-2.85 1.09h-5.1c-.87 0-1.3-.44-1.3-1.33zm30.79 50.59a1.97 1.97 0 0 1-.57-1.42V5.3c-.03-1.15.83-2.01 1.94-2h6.81c1.22-.02 1.96.79 1.94 2v55.45c0 .54-.17 1.03-.51 1.42-.35.39-.83.59-1.43.59h-6.81c-.53 0-.97-.19-1.37-.59m21.65-3.61c-4.38-3.86-5.93-11.09-5.68-17.59-.25-6.49 1.32-13.63 5.76-17.53 3.5-3.39 8.41-5.08 14.67-5.08 12.07-.11 19.75 7.02 20.27 18.92.53 7.25-.28 16.71-5.59 21.28-6.24 6.73-23.23 6.68-29.43 0m21.34-6.19c2.27-2.65 2.6-6.93 2.56-11.38.04-4.49-.28-8.74-2.6-11.44-2.85-3.78-10.37-3.7-13.22 0-2.9 3.1-2.66 10.17-2.51 14.7.11 3.57.94 6.28 2.51 8.11 2.81 3.68 10.48 3.7 13.26 0z" }, null, -1)
  ])))
}
export default { render: render }