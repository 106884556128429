import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 239.18 89.21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M109.66 40.23 98.65 65.85h-6.4L81.2 40.23h6.97l7.26 17.26 7.27-17.26zm8.96 0V65.7h-6.33V40.23zm31.08 0-11.01 25.62h-6.4l-11.05-25.62h6.96l7.27 17.26 7.27-17.26zm28.73 12.16c0 1.37-.05 2.39-.16 3.05h-18.15c.73 4.13 7.26 5.68 9.63 2.26h8.47c-3.68 13.88-27.57 9.86-26.4-4.79-.97-17.04 27.19-17.76 26.61-.52m-13.05-6.21q-4.26 0-5.31 4.26h10.36c-.45-2.84-2.14-4.26-5.05-4.26m35.99 13.93v5.58h-18.91V40.22h6.33v19.89zm4.99-16.55c12.39-12.2 31.71 6.21 19.21 18.84-12.45 12.12-31.62-6.22-19.21-18.84m4.57 14.64c6.64 6.94 16.61-3.94 10.11-10.45-6.61-7.01-16.62 3.93-10.11 10.45M71.18 37.8c-2.88-4.86-27.92 44.48-29.34 44.67-3.62-6.11-7.99-13.46-11.6-19.57S15.75 38.5 15.75 38.5c-1.45-2.56-5.48-.21-3.93 2.33l28.05 47.26c.41.69 1.16 1.12 1.96 1.12s1.55-.42 1.96-1.11l28.17-47.17c.65-1.08.3-2.49-.79-3.13zM11.97 51.2c-1.48-2.56-5.48-.2-3.92 2.33L28.6 88.09c.65 1.09 2.07 1.44 3.13.79 5.36-.29-20.1-36.29-19.76-37.68M8.08 63.79c-1.46-2.56-5.49-.2-3.92 2.33l13.03 21.97c1.48 2.57 5.47.19 3.92-2.33zm-3.81 13.1c-1.48-2.56-5.48-.17-3.91 2.35l5.33 8.87c1.5 2.57 5.47.16 3.92-2.35l-5.33-8.87z" }, null, -1),
    _createElementVNode("path", { d: "m41.05 74.34 19.09-33.45c1.49-2.55-2.53-4.86-3.97-2.26l-17.8 31.2 2.68 4.52zm6.99-33.39c1.5-2.56-2.53-4.86-3.97-2.27L32.22 59.43l2.67 4.52 13.15-23.01zm49.84 44.94-.28 1.55h-6.84l1.72-9.75h1.7l-1.45 8.2zm5.83-5.39c5.04.18 3.02 7.59-1.28 7.16-5.06-.16-3.04-7.62 1.28-7.16m-1.01 5.65c2.36.22 3.48-4 .74-4.14-2.38-.23-3.49 4.02-.73 4.14zm9.33-4.15c-4.36.33-1.65 6.89 1.13 2.9h1.69c-.93 3.66-7.75 3.67-6.78-.84.27-3.66 6.94-5.29 7.09-.86h-1.69c-.04-.78-.67-1.2-1.44-1.19z" }, null, -1),
    _createElementVNode("path", { d: "m123.59 80.66-1.21 6.79h-1.65l.09-.47c-7.8 3.64-5.39-9.63 1.02-5.84l.09-.47h1.66zm-4.47 5.51c.79 0 1.45-.31 2-.93l.41-2.37c-2.12-3.36-6.51 2.74-2.41 3.3m8.5-4.12c-.28 1.77-1.5 5.04 1.75 3.88l-.03 1.43c-4.26 1.48-4.23-2.43-3.37-5.32h-1.53l.23-1.39h1.55l.44-2.48h1.65l-.44 2.48h2.51l-.25 1.39h-2.51zm6.19-1.39-1.19 6.79h-1.65l1.19-6.79zm-1.47-2.45c-.05-1.34 2.08-1.32 2.05 0 .02 1.29-2.08 1.29-2.05 0m6.94 2.29c5.04.17 3.03 7.59-1.28 7.16-5.05-.17-3.04-7.61 1.28-7.16m-1 5.65c2.35.21 3.46-4.01.73-4.14-2.37-.22-3.47 4.02-.73 4.14m9.81-5.71c3.79-.07 2 4.79 1.73 7h-1.67c.62-2.39 1.63-7.86-2.61-4.51l-.8 4.5h-1.65l1.19-6.79h1.67l-.1.56c.71-.52 1.46-.77 2.24-.77zm15.96-3.18-1.81 10.18h-1.65l.09-.47c-7.76 3.59-5.41-9.64 1.03-5.84l.69-3.87zM159 86.17c1.89.23 2.27-1.9 2.41-3.29-2.12-3.37-6.5 2.66-2.41 3.28zm9.88-5.67c2.35-.08 3.3 2.17 2.6 4.2h-5.16c0 1.55 2.33 1.96 3.09.86h1.83c-5.17 6.73-10.4-4.21-2.36-5.07zm-.19 1.38c-1.03-.01-1.84.63-2.17 1.59h3.58c.13-1-.43-1.6-1.42-1.59zm15.69-1.22-4.18 6.79h-1.61l-1.95-6.79h1.77l1.27 4.68 2.89-4.68zm4.3-.16c2.34-.08 3.29 2.18 2.6 4.2h-5.17c0 1.56 2.33 1.96 3.1.86h1.82c-5.17 6.73-10.39-4.2-2.35-5.07zm-.2 1.38c-1.03 0-1.84.63-2.17 1.59h3.59c.12-1-.43-1.6-1.42-1.59m1.13-2.14h-1.5l1.45-2.14h1.83l-1.77 2.14zm5.87-2.48-1.81 10.18h-1.65l1.81-10.18zm4.67 3.24c5.05.18 3.03 7.59-1.28 7.16-5.04-.17-3.04-7.61 1.28-7.16m-1 5.65c2.35.22 3.46-4 .74-4.14-2.37-.23-3.49 4.01-.74 4.14" }, null, -1),
    _createElementVNode("path", { d: "m204.25 85.15 1.54.02c-.28 2.18 4.77.79 2.25-.31-8.29-.79 1.97-8.18 2.74-2.12l-1.54.02c.16-1.32-2.56-1.33-2.64-.17.43 1.41 4.04.34 3.77 2.54.11 3.19-6.56 3.68-6.12.04zM84.77 88.66c0 .49-1.07.51-1.06 0 .27-.21-.63-13.34.53-12.71 1.16-.69.27 12.56.53 12.71m90.71-54.21h-20.45v1.86h20.45zm-57.37-19.21c-3.87-6.91-2.58-2.11-6.27-4.22-.9-2.99 5.41-2.35 7.28-3.13 2.61.4.86 2.93-1.04 2.74 2.58 2.87 3.99 8.39 7.71 9.52 3.51-.17 4.29-2.71 4.71-5.73.78-3.11 3.3-5.46 5.2-7.63-2.15-4.55-2.95-3.85-7.42-2.99-1.26.19-1.9-2.13-.25-2.4 2.29.02 5.57-2.39 7.27-.24 1.73 2.74 3.27 5.61 5.12 8.26 16.45-5.15 22.48 19.32 4.71 20.72-8.44.23-13.75-10.44-8.35-16.99.47-.88 2.12-1.62 1.25-2.7-.4-.28-.61-1.5-1.17-1.11-7.86 5.57-1.61 15.11-6.74 16.63-2.11.19-3.91.38-4.56 2.78-2.54 10.56-19.04 9.54-20.66-1.08-1.71-7.6 5.88-14.21 13.21-12.45zm35.06 4.19c.27-5.17-5.36-9-10.17-7.4-.77.2-.79.27-.36.94.75 1.6 7.11 8.64 2.12 7.58-1.52-1.15-3.52-6.76-5.04-6.52-1.84 1.84-2.71 4.55-2.18 7.09 1.67 9.14 16.05 7.57 15.63-1.69m-31.28 1.99c-11.72-13.41-22.86 13-4.89 11.91 2.88-.66 5.72-2.99 5.98-6.07 0-.4-.17-.4-.46-.36-2.27.24-4.57.65-6.84.75-1.31-.08-2.06-.98-1.9-2.38 1.08-2.89 5.61-2.5 8.11-3.85" }, null, -1)
  ])))
}
export default { render: render }