import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 167.25 66"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M97.44 38.03c-3.48-4.23-10.7-3.07-15.5-3.2v21.82h7.79c9.42.39 14.67-12.25 7.71-18.62m-3.29 12.12c-1.82 2.1-5.01 1.86-7.53 1.82V39.5h3.11c5.41-.24 8.37 6.98 4.42 10.64zm23.92-12.58c-5.46-5.94-16.33-1.48-15.98 6.62v12.47h4.68v-4.67h9.36v4.67h4.67c-.47-4.78 1.66-15.59-2.73-19.08zm-1.94 9.73h-9.36c-.34-3.46.3-7.76 4.67-7.78 4.39.02 5.03 4.31 4.69 7.78m6.12-12.47h4.68v17.14h14.03v4.68h-18.71zm24.85 0h-4.68v21.82h4.68z" }, null, -1),
    _createElementVNode("path", { d: "M161.01 56.65h-12.46v-4.67h12.46c1.33.04 2.1-1.74 1.1-2.65-.54-.88-5.71-.31-6.56-.46-9.17.01-9.15-14.04 0-14.02h11.69v4.67c-1.24.25-12.7-.67-13.34.68-1.48 1.37-.36 4.08 1.65 3.99h5.46c8.17-.03 8.17 12.51 0 12.48zM38.5 44.17c.11-6.07-9.48-6.08-9.37 0v12.46h-4.68V44.16c-.35-8.37 11.09-12.67 16.37-6.2 5.28-6.47 16.72-2.16 16.37 6.2v12.47h-4.68V44.16c.06-2.51-2.16-4.74-4.68-4.68-2.52-.06-4.74 2.16-4.69 4.68v12.47h-4.68V44.16h.04zm128.75 17.15V66H76.59c-4.73 0-8.66-3.54-9.27-8.11 1.55.29 3.23.27 4.76-.04a4.67 4.67 0 0 0 4.51 3.47zM72.08 8.11c-1.55-.29-3.23-.28-4.76.05a4.65 4.65 0 0 0-4.51-3.48H.02V0h62.79c4.73.01 8.66 3.54 9.27 8.11m8.38 37.1c-.03-1.6-.5-3.19-1.25-4.6-.81-1.57-2.06-2.92-3.52-3.92-.87-.57-1.83-1.06-2.83-1.36-8.25-2.74-16.41 5.44-13.69 13.66.7 2.36 2.31 4.47 4.36 5.83 3.71 2.56 9 2.41 12.57-.33.67-.52 1.32-1.11 1.86-1.78.44-.5.84-1.13 1.18-1.71.93-1.75 1.46-3.79 1.32-5.79m-6.48 4.94c-5.79 5.74-14.55-3.02-8.82-8.82 5.79-5.72 14.53 3.03 8.82 8.82M14.6 9.42 9.76 24.01 4.9 9.42H0l7.23 21.76h5.06l7.22-21.76zm30.78 17.1V9.42h-4.66v21.76h18.65v-4.66z" }, null, -1),
    _createElementVNode("path", { d: "M77.31 12.61C67.08 2.6 51.92 17.78 61.94 27.99 72.17 38 87.32 22.82 77.31 12.61m-3.3 12.09c-5.73 5.77-14.53-3.04-8.79-8.79 5.76-5.75 14.57 3.06 8.79 8.79M39.29 14.09V9.42H20.65v21.76h18.64v-4.66H25.31v-3.89h10.87v-4.66H25.31v-3.88z" }, null, -1),
    _createElementVNode("path", { d: "M72.08 30.08h-4.76v5.63h4.76z" }, null, -1)
  ])))
}
export default { render: render }